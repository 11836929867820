import ImageGallery from 'react-image-gallery';
import './App.css';

const IMAGES_URL = process.env.PUBLIC_URL + '/assets/images/';

const images = [
  {
    original: IMAGES_URL + '2021-12-22_14-39_1.png',
    thumbnail: IMAGES_URL + '2021-12-22_14-39_1.png',
    description: 'Loni + Moli debugging'
  },
  {
    original: IMAGES_URL + '2022-09-26_16-59.png',
    thumbnail: IMAGES_URL + '2022-09-26_16-59.png',
    description: 'Loni debugging'
  },
  {
    original: IMAGES_URL + 'lm-1.jpg',
    thumbnail: IMAGES_URL + 'lm-1.jpg',
    description: 'Loni Moli'
  },
  {
    original: IMAGES_URL + 'lm-2.jpg',
    thumbnail: IMAGES_URL + 'lm-2.jpg',
    description: 'Loni Moli'
  },
  {
    original: IMAGES_URL + 'lm-3.jpg',
    thumbnail: IMAGES_URL + 'lm-3.jpg',
    description: 'Loni Moli'
  },
  {
    original: IMAGES_URL + 'lm-4.jpg',
    thumbnail: IMAGES_URL + 'lm-4.jpg',
    description: 'Loni Moli'
  },
  {
    original: IMAGES_URL + 'lm-5.jpg',
    thumbnail: IMAGES_URL + 'lm-5.jpg',
    description: 'Loni Moli'
  },
  {
    original: IMAGES_URL + 'lm-6.jpg',
    thumbnail: IMAGES_URL + 'lm-6.jpg',
    description: 'Loni Moli'
  },
  {
    original: IMAGES_URL + 'lm-7.jpg',
    thumbnail: IMAGES_URL + 'lm-7.jpg',
    description: 'Loni Moli'
  },
  {
    original: IMAGES_URL + 'lm-8.jpg',
    thumbnail: IMAGES_URL + 'lm-8.jpg',
    description: 'Loni Moli'
  },
  {
    original: IMAGES_URL + 'lm-9.jpg',
    thumbnail: IMAGES_URL + 'lm-9.jpg',
    description: 'Loni Moli'
  },
  {
    original: IMAGES_URL + 'lm-10.jpg',
    thumbnail: IMAGES_URL + 'lm-10.jpg',
    description: 'Loni Moli'
  },
  {
    original: IMAGES_URL + 'lm-11.jpg',
    thumbnail: IMAGES_URL + 'lm-11.jpg',
    description: 'Loni Moli'
  },
  {
    original: IMAGES_URL + 'lm-12.jpg',
    thumbnail: IMAGES_URL + 'lm-12.jpg',
    description: 'Loni Moli'
  },
  {
    original: IMAGES_URL + 'lm-13.jpg',
    thumbnail: IMAGES_URL + 'lm-13.jpg',
    description: 'Loni Moli'
  },
];

function App() {
  return (
    <div className="App">
      <ImageGallery items={images} />
    </div>
  );
}

export default App;
